<style lang="scss">
.allgemeines {
}
</style>

<template>
  <div class="allgemeines content container">
    <h1>Allgemeine Informationen</h1>
    <div class="tabelle-box">
      <div class="headline">
        Anzeigen- und Druckunterlagenschlüsse
      </div>
      <div class="row head-row hide-small">
        <div class="col-lg-3"></div>
        <div class="col-lg-3 text-align-left">Erscheinungstag</div>
        <div class="col-lg-3 text-align-center">Anzeigenschluss</div>
        <div class="col-lg-3 text-align-center">Druckunterlagenschluss</div>
      </div>
      <div class="row content-row">
        <div class="col-lg-3 text-align-left strong992px">Allgemein</div>
        <div class="col-6 text-align-left visible-small">Erscheinungstag</div>
        <div class="col-lg-3 col-6">Dienstag bis Samstag</div>
        <div class="col-6 text-align-left visible-small">Anzeigenschluss</div>
        <div class="col-lg-3 col-6">
          <div class="d-flex">
            <div class="col nopadding text-align-left">Produktionstag*</div>
            <div class="col nopadding text-align-right strong">10:00 Uhr</div>
          </div>
        </div>
        <div class="col-6 text-align-left visible-small">Druckunterlagenschluss</div>
        <div class="col-lg-3 col-6">
          <div class="d-flex">
            <div class="col nopadding text-align-left">Produktionstag*</div>
            <div class="col nopadding text-align-right strong">11:00 Uhr</div>
          </div>
        </div>
      </div>
      <div class="row content-row">
        <div class="col-lg-3 text-align-left strong992px">Allgemein</div>
        <div class="col-6 text-align-left visible-small">Erscheinungstag</div>
        <div class="col-lg-3 col-6">Sonntag und Montag</div>
        <div class="col-6 text-align-left visible-small">Anzeigenschluss</div>
        <div class="col-lg-3 col-6">
          <div class="d-flex">
            <div class="col nopadding text-align-left">Freitag</div>
            <div class="col nopadding text-align-right strong">10:00 Uhr</div>
          </div>
        </div>
        <div class="col-6 text-align-left visible-small">Druckunterlagenschluss</div>
        <div class="col-lg-3 col-6">
          <div class="d-flex">
            <div class="col nopadding text-align-left">Freitag</div>
            <div class="col nopadding text-align-right strong">11:00 Uhr</div>
          </div>
        </div>
      </div>
      <div class="row content-row">
        <div class="col-lg-3 text-align-left strong992px">Regionalseiten</div>
        <div class="col-6 text-align-left visible-small">Erscheinungstag</div>
        <div class="col-lg-3 col-6">Dienstag bis Samstag</div>
        <div class="col-6 text-align-left visible-small">Anzeigenschluss</div>
        <div class="col-lg-3 col-6">
          <div class="d-flex">
            <div class="col nopadding text-align-left">Produktionstag*</div>
            <div class="col nopadding text-align-right strong">09:00 Uhr</div>
          </div>
        </div>
        <div class="col-6 text-align-left visible-small">Druckunterlagenschluss</div>
        <div class="col-lg-3 col-6">
          <div class="d-flex">
            <div class="col nopadding text-align-left">Produktionstag*</div>
            <div class="col nopadding text-align-right strong">11:00 Uhr</div>
          </div>
        </div>
      </div>
      <div class="row content-row">
        <div class="col-lg-3 text-align-left strong992px">Regionalseiten</div>
        <div class="col-6 text-align-left visible-small">Erscheinungstag</div>
        <div class="col-lg-3 col-6">Sonntag</div>
        <div class="col-6 text-align-left visible-small">Anzeigenschluss</div>
        <div class="col-lg-3 col-6">
          <div class="d-flex">
            <div class="col nopadding text-align-left">Freitag</div>
            <div class="col nopadding text-align-right strong">09:00 Uhr</div>
          </div>
        </div>
        <div class="col-6 text-align-left visible-small">Druckunterlagenschluss</div>
        <div class="col-lg-3 col-6">
          <div class="d-flex">
            <div class="col nopadding text-align-left">Freitag</div>
            <div class="col nopadding text-align-right strong">11:00 Uhr</div>
          </div>
        </div>
      </div>
      <div class="row content-row">
        <div class="col-lg-3 text-align-left strong992px">Gesundheit</div>
        <div class="col-6 text-align-left visible-small">Erscheinungstag</div>
        <div class="col-lg-3 col-6">Sonntag</div>
        <div class="col-6 text-align-left visible-small">Anzeigenschluss</div>
        <div class="col-lg-3 col-6">
          <div class="d-flex">
            <div class="col nopadding text-align-left">Dienstag</div>
            <div class="col nopadding text-align-right strong">15:00 Uhr</div>
          </div>
        </div>
        <div class="col-6 text-align-left visible-small">Druckunterlagenschluss</div>
        <div class="col-lg-3 col-6">
          <div class="d-flex">
            <div class="col nopadding text-align-left">Donnerstag</div>
            <div class="col nopadding text-align-right strong">15:00 Uhr</div>
          </div>
        </div>
      </div>
      <div class="row content-row">
        <div class="col-lg-3 text-align-left strong992px">Reise</div>
        <div class="col-6 text-align-left visible-small">Erscheinungstag</div>
        <div class="col-lg-3 col-6">Samstag</div>
        <div class="col-6 text-align-left visible-small">Anzeigenschluss</div>
        <div class="col-lg-3 col-6">
          <div class="d-flex">
            <div class="col nopadding text-align-left">Mittwoch</div>
            <div class="col nopadding text-align-right strong">15:00 Uhr</div>
          </div>
        </div>
        <div class="col-6 text-align-left visible-small">Druckunterlagenschluss</div>
        <div class="col-lg-3 col-6">
          <div class="d-flex">
            <div class="col nopadding text-align-left">Donnerstag</div>
            <div class="col nopadding text-align-right strong">15:00 Uhr</div>
          </div>
        </div>
      </div>
      <div class="row content-row">
        <div class="col-lg-3 text-align-left strong992px">Beruf &amp; Bildung (Hauptmarkt)</div>
        <div class="col-6 text-align-left visible-small">Erscheinungstag</div>
        <div class="col-lg-3 col-6">Samstag</div>
        <div class="col-6 text-align-left visible-small">Anzeigenschluss</div>
        <div class="col-lg-3 col-6">
          <div class="d-flex">
            <div class="col nopadding text-align-left">Donnerstag</div>
            <div class="col nopadding text-align-right strong">15:00 Uhr</div>
          </div>
        </div>
        <div class="col-6 text-align-left visible-small">Druckunterlagenschluss</div>
        <div class="col-lg-3 col-6">
          <div class="d-flex">
            <div class="col nopadding text-align-left">Donnerstag</div>
            <div class="col nopadding text-align-right strong">16:00 Uhr</div>
          </div>
        </div>
      </div>
      <div class="row content-row">
        <div class="col-lg-3 text-align-left strong992px">Beruf &amp; Bildung (Zweitmarkt)</div>
        <div class="col-6 text-align-left visible-small">Erscheinungstag</div>
        <div class="col-lg-3 col-6">Mittwoch</div>
        <div class="col-6 text-align-left visible-small">Anzeigenschluss</div>
        <div class="col-lg-3 col-6">
          <div class="d-flex">
            <div class="col nopadding text-align-left">Dienstag</div>
            <div class="col nopadding text-align-right strong">10:00 Uhr</div>
          </div>
        </div>
        <div class="col-6 text-align-left visible-small">Druckunterlagenschluss</div>
        <div class="col-lg-3 col-6">
          <div class="d-flex">
            <div class="col nopadding text-align-left">Dienstag</div>
            <div class="col nopadding text-align-right strong">11:00 Uhr</div>
          </div>
        </div>
      </div>
      <div class="row content-row">
        <div class="col-lg-3 text-align-left strong992px">Wohnen (Hauptmarkt)</div>
        <div class="col-6 text-align-left visible-small">Erscheinungstag</div>
        <div class="col-lg-3 col-6">Freitag</div>
        <div class="col-6 text-align-left visible-small">Anzeigenschluss</div>
        <div class="col-lg-3 col-6">
          <div class="d-flex">
            <div class="col nopadding text-align-left">Donnerstag</div>
            <div class="col nopadding text-align-right strong">10:00 Uhr</div>
          </div>
        </div>
        <div class="col-6 text-align-left visible-small">Druckunterlagenschluss</div>
        <div class="col-lg-3 col-6">
          <div class="d-flex">
            <div class="col nopadding text-align-left">Donnerstag</div>
            <div class="col nopadding text-align-right strong">11:00 Uhr</div>
          </div>
        </div>
      </div>
      <div class="row content-row">
        <div class="col-lg-3 text-align-left strong992px">Wohnen (Zweitmarkt)</div>
        <div class="col-6 text-align-left visible-small">Erscheinungstag</div>
        <div class="col-lg-3 col-6">Mittwoch</div>
        <div class="col-6 text-align-left visible-small">Anzeigenschluss</div>
        <div class="col-lg-3 col-6">
          <div class="d-flex">
            <div class="col nopadding text-align-left">Dienstag</div>
            <div class="col nopadding text-align-right strong">10:00 Uhr</div>
          </div>
        </div>
        <div class="col-6 text-align-left visible-small">Druckunterlagenschluss</div>
        <div class="col-lg-3 col-6">
          <div class="d-flex">
            <div class="col nopadding text-align-left">Dienstag</div>
            <div class="col nopadding text-align-right strong">11:00 Uhr</div>
          </div>
        </div>
      </div>

      <div class="row content-row">
        <div class="col-lg-3 text-align-left strong992px">Familie, Garten, Essen + Trinken</div>
        <div class="col-6 text-align-left visible-small">Erscheinungstag</div>
        <div class="col-lg-3 col-6">Sonntag</div>
        <div class="col-6 text-align-left visible-small">Anzeigenschluss</div>
        <div class="col-lg-3 col-6">
          <div class="d-flex">
            <div class="col nopadding text-align-left">Dienstag</div>
            <div class="col nopadding text-align-right strong">15:00 Uhr</div>
          </div>
        </div>
        <div class="col-6 text-align-left visible-small">Druckunterlagenschluss</div>
        <div class="col-lg-3 col-6">
          <div class="d-flex">
            <div class="col nopadding text-align-left">Donnerstag</div>
            <div class="col nopadding text-align-right strong">16:00 Uhr</div>
          </div>
        </div>
      </div>
      <div class="row content-row">
        <div class="col-lg-3 text-align-left strong992px">Marktplatz Regional</div>
        <div class="col-6 text-align-left visible-small">Erscheinungstag</div>
        <div class="col-lg-3 col-6">Mittwoch</div>
        <div class="col-6 text-align-left visible-small">Anzeigenschluss</div>
        <div class="col-lg-3 col-6">
          <div class="d-flex">
            <div class="col nopadding text-align-left">Montag</div>
            <div class="col nopadding text-align-right strong">12:00 Uhr</div>
          </div>
        </div>
        <div class="col-6 text-align-left visible-small">Druckunterlagenschluss</div>
        <div class="col-lg-3 col-6">
          <div class="d-flex">
            <div class="col nopadding text-align-left">Montag</div>
            <div class="col nopadding text-align-right strong">12:00 Uhr</div>
          </div>
        </div>
      </div>
      <div class="row content-row">
        <div class="col-lg-3 text-align-left strong992px">Marktplatz Regional</div>
        <div class="col-6 text-align-left visible-small">Erscheinungstag</div>
        <div class="col-lg-3 col-6">Sonntag</div>
        <div class="col-6 text-align-left visible-small">Anzeigenschluss</div>
        <div class="col-lg-3 col-6">
          <div class="d-flex">
            <div class="col nopadding text-align-left">Mittwoch</div>
            <div class="col nopadding text-align-right strong">14:00 Uhr</div>
          </div>
        </div>
        <div class="col-6 text-align-left visible-small">Druckunterlagenschluss</div>
        <div class="col-lg-3 col-6">
          <div class="d-flex">
            <div class="col nopadding text-align-left">Mittwoch</div>
            <div class="col nopadding text-align-right strong">14:00 Uhr</div>
          </div>
        </div>
      </div>
      <div class="row content-row">
        <div class="col-lg-3 text-align-left strong992px">Mobilität (Hauptmarkt)</div>
        <div class="col-6 text-align-left visible-small">Erscheinungstag</div>
        <div class="col-lg-3 col-6">Freitag</div>
        <div class="col-6 text-align-left visible-small">Anzeigenschluss</div>
        <div class="col-lg-3 col-6">
          <div class="d-flex">
            <div class="col nopadding text-align-left">Donnerstag</div>
            <div class="col nopadding text-align-right strong">10:00 Uhr</div>
          </div>
        </div>
        <div class="col-6 text-align-left visible-small">Druckunterlagenschluss</div>
        <div class="col-lg-3 col-6">
          <div class="d-flex">
            <div class="col nopadding text-align-left">Donnerstag</div>
            <div class="col nopadding text-align-right strong">11:00 Uhr</div>
          </div>
        </div>
      </div>
      <div class="row content-row">
        <div class="col-lg-3 text-align-left strong992px">Mobilität (Zweitmarkt)</div>
        <div class="col-6 text-align-left visible-small">Erscheinungstag</div>
        <div class="col-lg-3 col-6">Mittwoch</div>
        <div class="col-6 text-align-left visible-small">Anzeigenschluss</div>
        <div class="col-lg-3 col-6">
          <div class="d-flex">
            <div class="col nopadding text-align-left">Dienstag</div>
            <div class="col nopadding text-align-right strong">10:00 Uhr</div>
          </div>
        </div>
        <div class="col-6 text-align-left visible-small">Druckunterlagenschluss</div>
        <div class="col-lg-3 col-6">
          <div class="d-flex">
            <div class="col nopadding text-align-left">Dienstag</div>
            <div class="col nopadding text-align-right strong">11:00 Uhr</div>
          </div>
        </div>
      </div>
      <div class="row content-row">
        <div class="col-lg-3 text-align-left strong992px">Traueranzeigen</div>
        <div class="col-6 text-align-left visible-small">Erscheinungstag</div>
        <div class="col-lg-3 col-6">Dienstag - Samstag</div>
        <div class="col-6 text-align-left visible-small">Anzeigenschluss</div>
        <div class="col-lg-3 col-6">
          <div class="d-flex">
            <div class="col nopadding text-align-left">Produktionstag*</div>
            <div class="col nopadding text-align-right strong">10:00 Uhr</div>
          </div>
        </div>
        <div class="col-6 text-align-left visible-small">Druckunterlagenschluss</div>
        <div class="col-lg-3 col-6">
          <div class="d-flex">
            <div class="col nopadding text-align-left">Produktionstag*</div>
            <div class="col nopadding text-align-right strong">11:00 Uhr</div>
          </div>
        </div>
      </div>
      <div class="row content-row">
        <div class="col-lg-3 text-align-left strong992px">Traueranzeigen</div>
        <div class="col-6 text-align-left visible-small">Erscheinungstag</div>
        <div class="col-lg-3 col-6">Sonntag</div>
        <div class="col-6 text-align-left visible-small">Anzeigenschluss</div>
        <div class="col-lg-3 col-6">
          <div class="d-flex">
            <div class="col nopadding text-align-left">Freitag</div>
            <div class="col nopadding text-align-right strong">12:00 Uhr</div>
          </div>
        </div>
        <div class="col-6 text-align-left visible-small">Druckunterlagenschluss</div>
        <div class="col-lg-3 col-6">
          <div class="d-flex">
            <div class="col nopadding text-align-left">Freitag</div>
            <div class="col nopadding text-align-right strong">12:00 Uhr</div>
          </div>
        </div>
      </div>
      <div class="info-txt-tabelle">* Produktionstag: Werktag vor dem Erscheinungstermin</div>
    </div>

    <h3>Platzierung</h3>
    <p>Gerne platzieren wir Raumanzeigen nach Wunsch gegen Leistung eines Platzierungszuschlages in der Höhe von 30 %.</p>
    <h3>Reseverierung</h3>
    <p>Wir sind jederzeit gerne bereit, Reservierungen entgegenzunehmen. Aus produktionstechnischen Gründen können diese allerdings nur so lange aufrechterhalten werden, als keine andere Fixbuchung vorliegt.</p>
    <h3>Veröffentlichung von Gutscheinen, Gewinnspielen, etc.</h3>
    <p>Für die Schaltung von Gutscheinen, Gewinnspielen, Kuponanzeigen und Verlosungen in der Kleinen Zeitung, auf Sonderwerbeformen und in Beilagen etc. sind unter anderem die Bestimmungen des Gesetzes gegen den unlauteren Wettbewerb (UWG) einzuhalten. Ihr multimedialer Werbeberater informiert Sie gerne über die aktuellen Erscheinungsmöglichkeiten.</p>
    <h3>Zahlungsmodalitäten</h3>
    <p>
      Alle im Tarif angegebenen Preise verstehen sich in Euro, auf Basis 4c exklusive 5 % Werbeabgabe und 20 % Umsatzsteuer – sofern nicht anders angeführt. Zahlbar bei Erhalt der Rechnung, ohne Abzug. Bei Abschluss einer Jahresvereinbarung vor der ersten Einschaltung für Raumanzeigen in der Kleinen Zeitung im Kalenderjahr 2022 gewähren wir einen Umsatzrabatt. Als Berechnungsgrundlage gilt der
      Umsatz pro Kunde und Agentur. Unberechtigter Skontoabzug oder Abzüge jeglicher Art werden nicht akzeptiert und dementsprechend nachgefordert.
    </p>
    <h3>Stornierungen</h3>
    <p>Bei Stornierungen von Anzeigen werden folgende Gebühren verrechnet: 1 Woche vor Anzeigenschluss 30 %, 1 Tag vor Anzeigenschluss 50 %. Nähere Informationen finden Sie in den AGB.</p>
    <h3>Feiertage</h3>
    <p>An Feiertagen gelten die Sonntagstarife.</p>
    <h3>Umsatzrabatte</h3>
    <p>
      Die Umsatzrabattstaffel gilt für Raumanzeigen in der Kleinen Zeitung (ausgenommen Parten) im Kalenderjahr 2022 bei Abschluss einer Jahresvereinbarung vor der ersten Einschaltung. <br />
      <strong>Berechnungsgrundlage:</strong> Umsatz pro Kunde und Agentur.<br />
      <strong>Zahlungsbedingungen:</strong> Bei Erhalt der Rechnung, ohne Abzug
    </p>
    <div class="tabelle-box tabelle-width-50">
      <div class="row headline">
        <div class="col-md-12">
          Umsatzrabatte
        </div>
      </div>
      <div class="row head-row hide-small">
        <div class="col-md-6 col-6 text-align-left">Jahressummen in EURO</div>
        <div class="col-md-6 col-6 text-align-right">Umsatzrabatt</div>
      </div>
      <div class="row content-row">
        <div class="col-md-6 col-6 text-align-left">23.089,00</div>
        <div class="col-md-6 col-6 text-align-right">2 %</div>
      </div>
      <div class="row content-row">
        <div class="col-md-6 col-6 text-align-left">69.267,00</div>
        <div class="col-md-6 col-6 text-align-right">4 %</div>
      </div>
      <div class="row content-row">
        <div class="col-md-6 col-6 text-align-left">115.445,00</div>
        <div class="col-md-6 col-6 text-align-right">6 %</div>
      </div>
      <div class="row content-row">
        <div class="col-md-6 col-6 text-align-left">184.712,00</div>
        <div class="col-md-6 col-6 text-align-right">8 %</div>
      </div>
      <div class="row content-row">
        <div class="col-md-6 col-6 text-align-left">277.068,00</div>
        <div class="col-md-6 col-6 text-align-right">10 %</div>
      </div>
      <div class="row content-row">
        <div class="col-md-6 col-6 text-align-left">392.513,00</div>
        <div class="col-md-6 col-6 text-align-right">12 %</div>
      </div>
      <div class="row content-row">
        <div class="col-md-6 col-6 text-align-left">554.136,00</div>
        <div class="col-md-6 col-6 text-align-right">14 %</div>
      </div>
      <div class="row content-row">
        <div class="col-md-6 col-6 text-align-left">785.026,00</div>
        <div class="col-md-6 col-6 text-align-right">16 %</div>
      </div>
    </div>
    <h3>Agenturprovision</h3>
    <p>
      Sie stellt einen Preisabschlag dar und wird Werbeagenturen, Werbeberatern und Werbemittlern gewährt, um den Aufwand für Mediaplanung, für die Übermittlung druckfertiger Unterlagen bzw. elektronische Übermittlung des fertigen Sujets, die Haftung für Copyright-Fragen, die Kosten einer allfälligen Vorfinanzierung und Kreativleistungen abzugelten. Sie wird nur gegen Vorlage eines gültigen
      Gewerbescheins, der die Art des Gewerbes ausweist (Werbeagentur, Werbeberater oder Werbemittler), gewährt. Bei Anlieferung nicht druckfertiger Unterlagen wird eine Produktionspauschale von 150,00 je in Auftrag gegebener Raumanzeige verrechnet.
    </p>
    <h3>Formatspiegel (Hauptblatt)</h3>
    <p>Satzspiegel: Breite 200 mm, Höhe 275 mm, 4 Spalten à 47 mm</p>

    <div class="row image-box justify-content-center align-self-center">
      <div class="col-md-4">
        <img src="@/assets/img/img-content/allgemeines/allgemeine-informationen-formatspiegel.jpg" alt="Formatspiegel" class="img-fluid shadow" />
      </div>
    </div>
    <h3>Formatspiegel</h3>
    <p>Die im Tarif angeführten Formate in der Kleinen Zeitung sind nur beispielhaft. Die nicht angeführten Formate errechnen sich nach dem Schema Spalte x Höhe x mm-Tarif.</p>
    <p>Zur Veranschaulichung der Preiszusammensetzung hier ein Beispiel: Sie möchten ein Inserat im Format 98 mm breit (2 Spalten) x 150 mm hoch im Bundeslandteil (Kombination, Mo-Fr) buchen. Folgende Berechnung liegt dem zugrunde: 2 x 150 x 26,79 = 8.037,00 netto</p>
    <!-- -->

    <!--<div class="download-pdf">
      <div class="line"><span>DOWNLOAD</span></div>
      <a href="../../pdf/allgemeines/Allgemeine-Information.pdf" target="_blank">
        <div class="row">
          <div class="col-8 justify-content-center align-self-center">
            Allgemeine Informationen
          </div>
          <div class="col-4 justify-content-end align-self-center text-right">
            <span>1.909 kb // PDF</span>
          </div>
        </div>
      </a>
    </div>-->
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  components: {},
});
</script>
